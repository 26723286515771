<template>
  <b-card
    class="custom-border-top-1px w-100 aspect-ratio-1-1"
  >
    <b-card-header class="p-0">
      <h4 class="text-primary font-weight-bolder">
        {{ dashboardDetail.name }}
      </h4>
    </b-card-header>
    <hr>
    <iframe
      :src="dashboardDetail.iframeUrl"
      frameborder="0"
    />
  </b-card>
</template>
<script>
import store from '@/store'
import reportStoreModule from '@/views/report/reportStoreModule'
import { onUnmounted } from '@vue/composition-api'

import { BCard, BCardHeader } from 'bootstrap-vue'
import useReportGeneral from './useReportGeneral'

export default {
  name: 'ReportGeneral',
  components: {
    BCard, BCardHeader,
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = 'report'
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
    })
    const { dashboardDetail, createEmbed } = useReportGeneral()


    return {
      dashboardDetail,
      createEmbed,
    }
  },
  computed: {
    dashboardId() {
      return this.$route.params.id
    },
  },
  created() {
    this.createEmbed(Number(this.dashboardId))
  },
}
</script>
<style lang="scss" scoped>
.card.custom-border-top-1px {
    width: 100%;
    /* height: 100%; */
    height: calc(100vh - calc(1rem + 4.45rem) - 1rem);
    iframe {
        width: 100%;
        height: calc(100% - 27px - 14px - 14px - 1px);
    }
}
</style>
